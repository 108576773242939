import YAML from "yaml";

// If you are editing this on GitHub, you might want to enable wrapping.
// There should be a button that says nowrap which can be changed to soft wrap.
// All You need to do is edit the text and press commit changes.
// This will cause your changes to take place on your website automatically.
export default YAML.parse(`
  - title: Home
    route: "/home/landing"
  - title: About Us
    route: "/home/about"
  - title: Health Care Planning
    route: "/services/Health-Care-Planning"
    sublinks:
    - title: What is Healthcare Planning?
      route: "/services/Health-Care-Planning"
    - title: Why is Healthcare Planning important?
      route: "/services/Health-Care-Planning"
    - title: How do we provide Healthcare Planning?
      route: "/services/Health-Care-Planning"
  - title: Estate Planning
    route: "/services/Estate-Planning-for-Families"
    sublinks:
    - title: Estate Planning for Families
      route: "/services/Estate-Planning-for-Families"
    - title: Estate Planning for Seniors and Elderly Adults
      route: "/services/Estate-Planning-for-Elderly-Adults"
    - title: Business and Succession Planning
      route: "/services/Business-and-Succession-Planning"
    - title: Wills/Trusts
      route: "/services/Wills-and-Trusts"
  - title: Other Services
    route: "/services/Probate-and-Trust-Administration"
    sublinks:
    - title: Probate and Trust Administration
      route: "/services/Probate-and-Trust-Administration"
`);
