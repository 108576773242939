<template>
  <div class="container">
    <div class="left">
      <h1>Contact Us</h1>
      <p class="client-name">
        {{ contactInfo.businessName }}
      </p>
      <div class="contact-info">
        <p>
          <b>Business Phone</b>:
          <a :href="`tel:${phoneLinkFormat}`">{{ phoneDisplayFormat }}</a>
        </p>
        <p>
          <b>Email</b>:
          <a :href="`mailto:${contactInfo.email}`">
            {{ contactInfo.email }}
          </a>
        </p>
        <p><b>Business Address</b>: {{ contactInfo.address }}</p>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.6776735026597!2d-81.35143308503723!3d28.699287087807566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e77272cb60afcd%3A0x8346c66ac84df3cc!2s212%20W%20Bay%20Ave%2C%20Longwood%2C%20FL%2032750!5e0!3m2!1sen!2sus!4v1605213988420!5m2!1sen!2sus"
        frameborder="0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      >
      </iframe>
    </div>
    <form class="right" :action="googleDocsUrl" method="post" ref="contactForm">
      <h2 class="title">{{ contactInfo.formQuestion }}</h2>
      <div class="input-row input-row--group">
        <div class="input-wrapper" id="input-first-name">
          <label for="first-name">First Name *</label>
          <input
            type="text"
            v-model="formState.firstName"
            id="first-name"
            placeholder="John"
            required
            name="entry.1323633987"
          />
        </div>
        <div class="input-wrapper">
          <label for="last-name">Last Name *</label>
          <input
            type="text"
            v-model="formState.lastName"
            id="last-name"
            placeholder="Smith"
            required
            name="entry.2061971970"
          />
        </div>
      </div>
      <div class="input-row">
        <label for="email-name">Email Address *</label>
        <input
          type="email"
          v-model="formState.email"
          id="email"
          placeholder="jsmith@example.com"
          required
          name="entry.1801964124"
        />
      </div>
      <div class="input-row">
        <label for="phone-number">Phone Number</label>
        <input
          type="tel"
          v-model="formState.phoneNumber"
          id="phone-number"
          placeholder="555-555-5555"
          name="entry.1045738262"
        />
      </div>
      <div class="input-row">
        <label for="feedback">How can we help? *</label>
        <textarea
          v-model="formState.feedback"
          id="feedback"
          required
          name="entry.554756033"
        />
      </div>
      <p class="input-hint">Fields marked with an * are required.</p>
      <button @click="submitForm" class="btn-send" :disabled="isSubmitDisabled">
        Send
      </button>
    </form>
    <Modal
      :title="modalProps.title"
      :body="modalProps.body"
      :isOpen="modalProps.isOpen"
      :onClose="onModalClose"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "@/components/Modal.vue";
import contactInfo from "@/editable/ContactSection.ts";

interface ContactInfo {
  businessName: string;
  phone: string;
  email: string;
  address: string;
}

const defaultFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  feedback: ""
};

export default defineComponent({
  name: "ContactSection",
  components: {
    Modal
  },
  data() {
    return {
      contactInfo: contactInfo as ContactInfo,
      googleDocsUrl:
        "https://docs.google.com/forms/u/0/d/e/1FAIpQLSc2KifG0h0tiGDLJgdFjqbrNm55pAXSSkbColD0nTjjcQMcRw/formResponse",
      isSubmitDisabled: false,
      formState: {
        ...defaultFormState
      },
      modalProps: {
        isOpen: false,
        title: "",
        body: ""
      }
    };
  },
  computed: {
    phoneLinkFormat(): string {
      return `+1-${this.contactInfo.phone}`;
    },
    phoneDisplayFormat(): string {
      const retval = this.contactInfo.phone.replace("-", ") ");
      return `(${retval}`;
    }
  },
  methods: {
    showModal(title: string, body: string) {
      this.modalProps = {
        title,
        body,
        isOpen: true
      };
    },
    onModalClose() {
      this.modalProps.isOpen = false;
    },
    clearForm() {
      (this.$refs.contactForm as HTMLFormElement).reset();
      this.formState = { ...defaultFormState };
    },
    isInputValid() {
      // Matches:
      //  (123) 456-7890
      //  (123)456-7890
      //  123-456-7890
      //  123.456.7890
      //  1234567890
      //  +31636363634
      //  075-63546725
      const phoneNumberRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
      // Matches: text@text.text
      const emailRegex = /\S+@\S+\.\S+/;
      const {
        firstName,
        lastName,
        email,
        feedback,
        phoneNumber
      } = this.formState;

      if (
        !firstName.trim() ||
        !lastName.trim() ||
        !email.trim() ||
        !feedback.trim()
      ) {
        return false;
      }

      if (!emailRegex.test(email)) {
        return false;
      }

      if (phoneNumber.trim() && !phoneNumberRegex.test(phoneNumber)) {
        return false;
      }

      return true;
    },
    submitForm(event: Event) {
      event.preventDefault();

      if (!this.isInputValid()) {
        this.showModal(
          "Check your responses",
          `Please make sure you've filled out the required fields and
          all of your responses are valid, then try again.`
        );
        return;
      }

      this.isSubmitDisabled = true;

      const contactForm = this.$refs.contactForm as HTMLFormElement;

      fetch(this.googleDocsUrl, {
        method: "POST",
        body: new FormData(contactForm),
        mode: "no-cors"
      })
        .then(() => {
          this.clearForm();
          this.showModal(
            "Thanks!",
            `Your response has been recorded. We'll be
            sure to get back to you shortly.`
          );
        })
        .catch(() => {
          this.showModal(
            "Uh-Oh!",
            `Something went wrong while processing
            your request. Please try again later.`
          );
        })
        .finally(() => (this.isSubmitDisabled = false));
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/mixins/color-palette";
@import "@/mixins/media";

$breakpoint-lg: 1050px;
$breakpoint-md: 650px;

* {
  color: white;
  font-family: Merriweather;
}

h1 {
  font-size: 50px;
  margin-bottom: 30px;
}

h2 {
  margin: 52px 0 48px 0;
  font-weight: normal;
}

iframe {
  margin-top: 32px;
  width: 100%;
  height: 100%;
  border: none;

  @include breakpoint($breakpoint-lg) {
    height: 350px;
  }
}

input,
textarea {
  color: black;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 100%;
  padding: 10px 0px 10px 12px;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
  height: 200px;
}

label {
  display: block;
  margin-bottom: 12px;
  font-size: 16px;
}

#input-first-name {
  @include breakpoint($breakpoint-md) {
    margin-bottom: 32px;
  }
}

.container {
  overflow: hidden;
  display: flex;
  margin-top: 128px;
  background-color: color(blue, base);

  @include breakpoint($breakpoint-lg) {
    flex-direction: column;
  }
}

.left {
  padding: 32px 64px;
  display: flex;
  flex: 1;
  flex-direction: column;

  @include breakpoint($breakpoint-md) {
    padding: 32px;
  }

  .client-name {
    text-decoration: underline;
    font-size: 22px;
    margin-bottom: 32px;
  }

  .contact-info {
    font-size: 16px;
    line-height: 32px;
  }
}

.right {
  padding: 32px 64px;
  flex: 1;

  @include breakpoint($breakpoint-md) {
    padding: 0 32px 32px 32px;
  }

  .input-row {
    margin-bottom: 32px;
  }

  .input-row--group {
    display: flex;
    justify-content: space-between;

    @include breakpoint($breakpoint-md) {
      flex-direction: column;
    }
  }

  .input-wrapper {
    width: 47%;

    @include breakpoint($breakpoint-md) {
      width: 100%;
    }
  }

  .input-hint {
    font-size: 16px;
  }

  .btn-send {
    $color-disabled: rgba(255, 255, 255, 0.5);

    border: 1px solid white;
    background-color: transparent;
    font-size: 16px;
    border-radius: 10px;
    width: 100%;
    margin-top: 32px;
    padding: 12px 6px;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &:disabled {
      cursor: not-allowed;
      border: 1px solid $color-disabled;
      color: $color-disabled;
    }

    &:not([disabled]):hover {
      color: color(blue, base);
      background-color: white;
      cursor: pointer;
    }
  }
}
</style>
