import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Services from "../views/Services.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Root",
    component: Home
  },
  {
    path: "/home/:id?",
    name: "Home",
    component: Home
  },
  {
    path: "/services/:id?",
    name: "Services",
    component: Services
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
