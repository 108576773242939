<template>
  <div :id="titleToID" class="service" v-bind:class="{ reverse: isReversed }">
    <img :src="image" />
    <div id="text-box">
      <p id="title" v-bind:class="{ white: isWhite }">
        {{ title }}
      </p>
      <div id="description" v-bind:class="{ white: isWhite }">
        <p>
          {{ description }}
        </p>

        <div v-for="item of qa" v-bind:key="item.question">
          <p class="question" v-bind:class="{ white: isWhite }">
            {{ item.question }}
          </p>
          <p v-html="item.answer" class="answer"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

interface QA {
  question: string;
  answer: string;
}

export default defineComponent({
  name: "ServiceComponent",
  props: {
    title: String,
    description: String,
    qa: Array,
    image: String,
    isReversed: Boolean,
    isWhite: Boolean
  },
  computed: {
    titleToID(): string | undefined {
      return this.title?.replaceAll(" ", "-");
    }
  },
  mounted() {
    window.addEventListener("scroll", () =>
      requestAnimationFrame(() => {
        if (!this.titleToID) return;
        const service = document.getElementById(this.titleToID);
        if (!service) return;

        const scrollProgress =
          1 - service.getBoundingClientRect().top / window.outerHeight;
        let slideProgress = 400 - service.getBoundingClientRect().top;

        if (slideProgress > 0) slideProgress = 0;
        if (this.isReversed) slideProgress *= -1;

        service.style.opacity = `min(${scrollProgress} + 0.2, 1)`;
        service.style.transform = `translateX(${slideProgress}px)`;
      })
    );
  }
});
</script>

<style lang="scss" scoped>
@import "@/mixins/color-palette";
@import "@/mixins/media";

.service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;

  @include mobile-view() {
    height: auto;
    flex-direction: column;
  }

  img {
    object-fit: cover;
    width: 50%;
    height: 80%;
    border-radius: 0 25px 25px 0;

    @include mobile-view() {
      align-self: flex-start;
      width: 80%;
      height: max(250px, 50vw);
      margin-top: 100px;
      margin-bottom: 25px;
    }
  }

  #text-box {
    flex: 1;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 15px 0;
      text-align: left;
      font-size: 1.2rem;
    }

    #title {
      font-size: 1.8rem;
      width: 85%;
      color: color(blue, base);
      font-weight: bold;
    }

    #description {
      width: 85%;
      overflow: auto;

      .question {
        font-size: 1.5rem;
        color: color(blue, base);
        font-weight: bold;
      }

      .answer li {
        margin: 100px 0;
      }
    }
  }

  #description {
    overflow: auto;
  }
}

.reverse {
  flex-direction: row-reverse;

  @include mobile-view() {
    flex-direction: column;
  }

  img {
    @include mobile-view() {
      align-self: flex-end;
    }

    border-radius: 25px 0 0 25px !important;
  }
}

.white {
  @include desktop-view() {
    color: white !important;
  }
}
</style>
