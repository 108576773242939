<template>
  <section id="about">
    <div v-for="(about, index) of aboutText" :key="about.title">
      <div class="container-about" :class="{ reverse: index % 2 }">
        <img :src="about.image" alt="" />
        <div class="description">
          <p class="header">About Us</p>
          <p class="title">{{ about.title }}</p>
          <p class="details">{{ about.details }}</p>
        </div>
      </div>
    </div>
    <div id="triangle-top-left"></div>
    <div id="triangle-bottom-right"></div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import aboutText from "@/editable/AboutSection.ts";

// Include all images
for (const about of aboutText) {
  about.image = require(`@/assets/${about.image}`);
}

interface About {
  image: string;
  title: string;
  details: string;
}

export default defineComponent({
  name: "AboutSection",
  data() {
    return {
      aboutText: aboutText as About[]
    };
  },
  mounted() {
    window.addEventListener("scroll", () =>
      requestAnimationFrame(() => {
        const containers = document.getElementsByClassName(
          "container-about"
        ) as HTMLCollectionOf<HTMLElement>;

        for (const container of containers) {
          const scrollProgress =
            1 - container.getBoundingClientRect().top / window.outerHeight;
          let slideProgress = 400 - container.getBoundingClientRect().top;

          if (slideProgress > 0) slideProgress = 0;
          if (container.classList.contains("reverse")) slideProgress *= -1;

          container.style.opacity = `min(${scrollProgress} + 0.3, 1)`;
          container.style.transform = `translateX(${slideProgress}px)`;
        }
      })
    );
  }
});
</script>

<style scoped lang="scss">
@import "@/mixins/color-palette";
@import "@/mixins/media";

$slide-speed: 25px;

section {
  overflow: hidden;
  position: relative;
}

.reverse {
  flex-direction: row-reverse;
}

.container-about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75vh;
  padding: 50px 0;

  @include mobile-view() {
    height: auto;
    flex-direction: column;
  }

  &.reverse {
    img {
      margin-left: 0;
      margin-right: 50px;
    }
  }

  img {
    object-fit: cover;
    width: 40%;
    height: 100%;
    margin-left: 50px;

    @include mobile-view() {
      width: 80%;
      height: 50vh;
      margin: 0 !important;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    height: 100%;

    .header {
      font-family: Merriweather;
      font-style: normal;
      font-weight: bold;
      text-decoration: underline;
      font-size: 20px;
      color: color(blue, base);
    }

    .title {
      font-family: Merriweather;
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      line-height: 69px;
      margin: 0;
      color: color(blue, base);
    }

    .details {
      overflow: auto;
      flex: 1;
      font-family: PT Serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 44px;
      margin: 0;
      margin-top: 20px;
    }
  }
}

#triangle-top-left {
  z-index: -1;
  position: absolute;
  width: 20vh;
  height: 20vh;
  top: 0;
  background-color: color(blue, lighter);
  clip-path: polygon(100% 0, 0 0, 0 100%);
}

#triangle-bottom-right {
  z-index: -1;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20vh;
  height: 20vh;
  background-color: color(blue, lighter);
  clip-path: polygon(100% 0, 100% 100%, 0 100%);

  @include mobile-view() {
    display: none;
  }
}
</style>
