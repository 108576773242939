
import { defineComponent } from "vue";

interface QA {
  question: string;
  answer: string;
}

export default defineComponent({
  name: "ServiceComponent",
  props: {
    title: String,
    description: String,
    qa: Array,
    image: String,
    isReversed: Boolean,
    isWhite: Boolean
  },
  computed: {
    titleToID(): string | undefined {
      return this.title?.replaceAll(" ", "-");
    }
  },
  mounted() {
    window.addEventListener("scroll", () =>
      requestAnimationFrame(() => {
        if (!this.titleToID) return;
        const service = document.getElementById(this.titleToID);
        if (!service) return;

        const scrollProgress =
          1 - service.getBoundingClientRect().top / window.outerHeight;
        let slideProgress = 400 - service.getBoundingClientRect().top;

        if (slideProgress > 0) slideProgress = 0;
        if (this.isReversed) slideProgress *= -1;

        service.style.opacity = `min(${scrollProgress} + 0.2, 1)`;
        service.style.transform = `translateX(${slideProgress}px)`;
      })
    );
  }
});
