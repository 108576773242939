import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

createApp(App)
  .use(store)
  .use(router)
  .mount("#app");

// Add scroll css variable for animations
let scroll = window.pageYOffset / window.innerHeight;
document.body.style.setProperty("--scroll", scroll.toString());
window.addEventListener(
  "scroll",
  () => {
    scroll = window.pageYOffset / window.innerHeight;
    document.body.style.setProperty("--scroll", scroll.toString());
  },
  false
);

// Global css variables
document.body.style.setProperty("--navbar-height", "56px");
