import YAML from "yaml";

// If you are editing this on GitHub, you might want to enable wrapping.
// There should be a button that says nowrap which can be changed to soft wrap.
// All You need to do is edit the text and press commit changes.
// This will cause your changes to take place on your website automatically.
export default YAML.parse(`
  name: Joy Carmichael
  paragraphs:
    - 'Joy has been practicing law for more than 20 years. Estate planning has been a primary and most rewarding area of practice for her at various points in her legal career. However, Joy also has a legal background in real estate, business, contracts, local government law, and even homeschooling, as a former “Homeschooling Mom”.'
    - 'Joy utilizes her knowledge and experience in those complementary areas of law to advise her clients in the handling of legal questions and issues that arise in the midst of estate planning, such as in obtaining the status of legal title of real property; whether a proposed sale of a business should have a more comprehensive sale and purchase contract; and how to obtain a change in zoning from agriculture to commercial for a particular piece of prime real estate. In her private practice as an estate planning attorney, Joy describes herself as “more than an estate planning attorney”. She will be your confidante’, consultant and legal advisor in other areas of law and life. Essentially, she becomes your family’s lawyer, your attorney available for quick legal guidance and your legal point of contact for referral to other attorneys that may be more suitable to handle a particular matter.'
`);
