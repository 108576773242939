<template>
  <div class="modal-container" v-if="open">
    <div class="modal">
      <button class="close-btn" @click="closeModal">&times;</button>
      <div class="modal-content">
        <h1 class="title">{{ title }}</h1>
        <p class="content">{{ body }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Modal",
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    onClose: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      open: this.$props.isOpen
    };
  },
  methods: {
    closeModal() {
      this.open = false;
      if (this.$props.onClose) {
        this.$props.onClose();
      }
    },
    onEscPress(event: KeyboardEvent) {
      if (event.key === "Escape") {
        this.closeModal();
      }
    }
  },
  mounted() {
    document.addEventListener("keyup", this.onEscPress);
  },
  unmounted() {
    document.removeEventListener("keyup", this.onEscPress);
  },
  watch: {
    isOpen(newValue: boolean) {
      this.open = newValue;
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/mixins/color-palette";
@import "@/mixins/media";

$breakpoint-sm: 600px;

.modal-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  flex-direction: column;
  align-content: center;
  justify-content: center;
  display: flex;
}

.modal {
  background-color: white;
  position: relative;
  width: 500px;
  margin: 0 auto;
  text-align: center;
  font-family: Merriweather;

  @include breakpoint($breakpoint-sm) {
    width: 90%;
  }
}

.modal-content {
  padding: 24px;
}

.close-btn {
  position: absolute;
  right: 0;
  color: color(blue, darker);
  background-color: transparent;
  border: none;
  font-size: 32px;
  padding: 8px 16px;
  cursor: pointer;
}

.title {
  color: color(blue, darker);
}

.content {
  color: color(blue, base);
  padding: 0 16px;
  line-height: 30px;
}
</style>
