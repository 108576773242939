<template>
  <div class="container">
    <Navbar :isFixed="true"></Navbar>
    <div v-for="(service, index) of services" v-bind:key="service.title">
      <ServiceComponent
        :title="service.title"
        :description="service.description"
        :qa="service.qa"
        :image="service.image"
        :isReversed="isReversed(index)"
        :isWhite="isWhite(index)"
      ></ServiceComponent>
    </div>
    <div class="background-polygon"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ServiceComponent from "@/components/ServiceComponent.vue";
import Navbar from "@/components/Navbar.vue";
import servicesText from "@/editable/Services.ts";

// Include all images
for (const service of servicesText) {
  service.image = require(`@/assets/${service.image}`);
}

interface QA {
  question: string;
  answer: string;
}

interface Service {
  title: string;
  description?: string;
  qa?: QA[];
  image: string;
}

export default defineComponent({
  name: "Services",
  components: {
    ServiceComponent,
    Navbar
  },
  data() {
    return {
      services: servicesText as Service[]
    };
  },
  methods: {
    isReversed(index: number): boolean {
      return index % 2 == 1;
    },
    isWhite(index: number): boolean {
      return index >= 3;
    },
    scrollToSection(section: string) {
      const navbarHeight = parseInt(
        document.body.style.getPropertyValue("--navbar-height")
      );
      if (!section) return;
      const element = document.getElementById(section);
      if (!element) return;

      const y =
        element.getBoundingClientRect().top + window.pageYOffset - navbarHeight;

      window.scrollTo({
        top: y,
        behavior: "smooth"
      });
      this.$route.params.id = "";
    }
  },
  watch: {
    $route() {
      this.scrollToSection(this.$route.params.id as string);
    }
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0 });

    setTimeout(() => {
      this.scrollToSection(this.$route.params.id as string);
    }, 100);
  }
});
</script>

<style lang="scss" scoped>
@import "@/mixins/color-palette";
@import "@/mixins/media";

.container {
  position: relative;
  overflow: hidden;
}

.background-polygon {
  z-index: -1;
  position: absolute;
  bottom: 0;
  height: calc(50% + 50vh);
  clip-path: polygon(0 0, 100% 100vh, 100% 100%, 0% 100%);
  width: 100%;
  background-color: color(blue, base);

  @include mobile-view() {
    display: none;
  }
}
</style>
