
import { defineComponent } from "vue";

export default defineComponent({
  name: "Modal",
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    onClose: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      open: this.$props.isOpen
    };
  },
  methods: {
    closeModal() {
      this.open = false;
      if (this.$props.onClose) {
        this.$props.onClose();
      }
    },
    onEscPress(event: KeyboardEvent) {
      if (event.key === "Escape") {
        this.closeModal();
      }
    }
  },
  mounted() {
    document.addEventListener("keyup", this.onEscPress);
  },
  unmounted() {
    document.removeEventListener("keyup", this.onEscPress);
  },
  watch: {
    isOpen(newValue: boolean) {
      this.open = newValue;
    }
  }
});
