import YAML from "yaml";

// If you are editing this on GitHub, you might want to enable wrapping.
// There should be a button that says nowrap which can be changed to soft wrap.
// All You need to do is edit the text and press commit changes.
// This will cause your changes to take place on your website automatically.
export default YAML.parse(`
  - image: black-family.jpg
    title: "We Provide Estate Planning Services."
    details: "Crown Heritage Estate Planning is a law firm that provides estate planning services and ancillary services that enhance the estate planning process and the process of administration of estates. We refer to estate planning as “life planning”. We assist you where you are in the growth spectrum of life. We take time to discuss your plans and goals as proceed through the various building and rebuilding phases of life. Our firm specializes in excellence and care for you and your family."
  - image: white-family.jpg
    title: "We Focus On Health, Wealth, and Wellness"
    details: "At Crown Heritage Law, we focus on the aspects of health, as well as wealth to ensure that you have appropriate plans to maintain your wellbeing and your wealth. We prioritize remaining abreast of creative and modern techniques to assist you in protecting your estate and reducing the tax burden on your family. We blend good longstanding traditional legal services with modern enhancements to meet our clients’ needs. Our primary goal is to provide peace of mind to you and your family. You will appreciate the Crown Heritage Law experience."
`);
