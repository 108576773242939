<template>
  <div id="container">
    <Navbar :isClear="true" :isFixed="true"></Navbar>
    <LandingSection id="landing"></LandingSection>
    <AboutSection id="about"></AboutSection>
    <StaffSection id="staff"></StaffSection>
    <ServicesSection id="services"></ServicesSection>
    <ContactSection id="contact"></ContactSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LandingSection from "@/components/LandingSection.vue";
import AboutSection from "@/components/AboutSection.vue";
import StaffSection from "@/components/StaffSection.vue";
import ServicesSection from "@/components/ServicesSection.vue";
import ContactSection from "@/components/ContactSection.vue";
import Navbar from "@/components/Navbar.vue";

export default defineComponent({
  name: "Home",
  components: {
    LandingSection,
    AboutSection,
    StaffSection,
    ServicesSection,
    ContactSection,
    Navbar
  },
  methods: {
    scrollToSection(section: string) {
      const navbarHeight = parseInt(
        document.body.style.getPropertyValue("--navbar-height")
      );
      if (!section) return;
      const element = document.getElementById(section);
      if (!element) return;

      const y =
        element.getBoundingClientRect().top + window.pageYOffset - navbarHeight;

      window.scrollTo({
        top: y,
        behavior: "smooth"
      });
      this.$route.params.id = "";
    }
  },
  watch: {
    $route() {
      this.scrollToSection(this.$route.params.id as string);
    }
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0 });

    setTimeout(() => {
      this.scrollToSection(this.$route.params.id as string);
    }, 100);
  }
});
</script>

<style scoped lang="scss">
template,
#container {
  height: 100%;
}

LandingSection {
  height: 100%;
}
</style>
