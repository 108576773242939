import YAML from "yaml";

// If you are editing this on GitHub, you might want to enable wrapping.
// There should be a button that says nowrap which can be changed to soft wrap.
// All You need to do is edit the text and press commit changes.
// This will cause your changes to take place on your website automatically.
export default YAML.parse(`
- title: Health Care Planning
  qa:
  - question: What is Health Care Planning?
    answer: Simply stated, Health Care Planning is the process of stating your preferences for the medical treatment that you would like to receive if you are unable to communicate those medical preferences to a health care provider.
  - question: Why is Health Care planning important?
    answer: Health Care Planning is important to provide you with appropriate legal documents that your health care providers can read to understand your choices for medical treatment when you are too ill or otherwise unable to communicate with your health care providers.  You may become unable to speak with your health care providers as a result of an injury or an illness.  In addition, Health Care Planning allows you to give legal authority to family members or friends to speak on your behalf to your health care providers while you are unable to communicate.
  - question: How do we provide Health Care planning?
    answer: We meet with you to discuss the various situations in which health care planning might be useful and advise you of the requirements of Florida law. As we receive your preferences for medical treatment, we guide you in stating those preferences in legal terms. Finally, we prepare written documents that will serve as your advance directives.
  image: doctor.png
- title: Estate Planning for Families
  qa:
  - question: Which families need an estate plan?
    answer: All families. A family may consist of a single person to numerous people within an extended family. Also, adult family members from 18 years of age and upward can benefit from an estate plan.
  - question: Is estate planning for wealthy families only?
    answer: No. In the past, estate planning was an event in which wealthy families planned for the distribution of their wealth to their children through the creation of a last will and testament or a trust. Now, modern estate planning consists of the preparation of several legal documents to ensure that every adult has proper legal documents to manage the various anticipated and unanticipated events of life and death. The anticipated events of life include the possibility of buying your first home, marriage, the birth of children and providing for their education, and then, entering the sunset years of life. The unanticipated events include hospitalization due to an automobile accident, long-term illnesses, emergency surgeries and other unanticipated events. The unanticipated events of life bring us to a standstill and might leave us unable to speak or to handle our responsibilities. Modern estate planning includes preparation of legal documents that provide legal authorization.
  - question: What is a modern estate plan?
    answer: 'A modern estate plan might include several documents:  such as documents that provide information to health care providers in the event of a medical emergency; a document that gives authority to a trusted relative or friend to manage your financial affairs if you are ill or otherwise unavailable to manage your financial affairs, and a document that sets forth your instructions for distribution of your assets after your death.'
  image: asian-family.png
- title: Estate Planning for Elderly Adults
  qa:
  - question: 'What are two important benefits that seniors gain from the creation of an estate plan?'
    answer:
      'Seniors receive two key benefits from the creation of an estate plan:\
      <ol>\
      <li> The peace of mind of planning for the maintenance of their health and the preservation of their assets for their lifetime.\
      <li> Planning for the distribution of their assets to loved ones and charities after their death.\
      </ol>'
  - question: What are special considerations for elderly adults in the estate planning process?
    answer: As we enter our later sunset years, the task of preparing or revising an estate plan can feel overwhelming.  Elderly adults might need a helping hand and support in moving forward to prepare or revise their estate plans.   A trusted friend or an adult child, who has the well-being of the elderly adult as the most important consideration in the estate planning process, can provide support to aid an elderly adult in moving forward with the estate planning process. In addition, providing appropriate long-term health care for elderly adults is a foundational part of estate planning.
  image: old-woman.png
- title: Business and Succession Planning
  qa:
  - question: What is business succession planning?
    answer: Business succession planning is planning for the transfer of your business when you are no longer able or willing to manage your business. Your business succession plan should include a plan for temporary management of your business in the event of an unanticipated period of illness or unavailability, and a retirement plan that provides for new leadership to continue your business or to sell your business.
  - question: Which businesses need a succession plan?
    answer: All businesses need a succession plan, from the smallest sole proprietor business to a large corporation.
  - question: Is business succession planning a part of estate planning?
    answer: Yes. Business succession planning is an important part of a business owner’s estate plan.
  image: meeting.png
- title: Wills and Trusts
  qa:
  - question: What is a Last Will and Testament, which is also known simply as a “Will”?
    answer: A Will is a legal declaration of a person’s desires for the distribution of that person’s property or estate after death
  - question: What is a Trust?
    answer: A Trust is a legal document that provides for a person or corporate entity to hold title to assets for the benefit of another person.
  - question: Do you need a Will only, or a Trust and a Will?
    answer: Whether you need a Will or a Trust and a Will depends on your particular circumstances.  The primary purpose of a Will is to provide directions for the distribution of all of your assets after death.  However, a Trust enables you to manage your assets during your lifetime and to provide for the continued management of your assets after your death.  A Trust also provides for the distribution of some or all of your assets during your lifetime and after your death.
  image: house.png
- title: Probate and Trust Administration
  qa:
  - question: What is Probate?
    answer: Probate is the judicial process of “proving” a person’s Last Will and Testament and administering a person’s estate after death.
  - question: What is Trust administration?
    answer: Trust administration refers to the trustee’s management of trust property according to the terms of the trust document for the benefit of the beneficiaries of the trust property.
  image: handshake.png
`);
