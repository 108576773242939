
import { defineComponent } from "vue";
import services from "@/editable/ServicesSection.ts";

interface Service {
  title: string;
  icon: string;
  // an array of descriptions
  description: string[];
}

export default defineComponent({
  name: "ServicesSection",
  methods: {
    titleToAddress(title: string): string | undefined {
      return "/services/" + title.replaceAll(" ", "-");
    }
  },
  data() {
    return {
      services: services
    };
  }
});
