
import { defineComponent } from "vue";
import links from "@/editable/Navbar.ts";

interface Link {
  title: string;
  route: string;
  sublinks: Link[];
}

export default defineComponent({
  name: "Navbar",
  props: {
    isClear: Boolean,
    isFixed: Boolean
  },
  data() {
    return {
      isMenuOpen: false,
      links: links as Link[]
    };
  },
  methods: {
    showDropdown(id: string) {
      const dropdown = document.getElementById(id);
      dropdown?.classList.remove("hidden");
    },

    hideDropdown(id: string) {
      const dropdown = document.getElementById(id);
      dropdown?.classList.add("hidden");
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    toggleMobileDropdown(id: string) {
      const dropdown = document.getElementById(id);
      dropdown?.classList.toggle("hidden");
    }
  }
});
