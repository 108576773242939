
import { defineComponent } from "vue";
import ServiceComponent from "@/components/ServiceComponent.vue";
import Navbar from "@/components/Navbar.vue";
import servicesText from "@/editable/Services.ts";

// Include all images
for (const service of servicesText) {
  service.image = require(`@/assets/${service.image}`);
}

interface QA {
  question: string;
  answer: string;
}

interface Service {
  title: string;
  description?: string;
  qa?: QA[];
  image: string;
}

export default defineComponent({
  name: "Services",
  components: {
    ServiceComponent,
    Navbar
  },
  data() {
    return {
      services: servicesText as Service[]
    };
  },
  methods: {
    isReversed(index: number): boolean {
      return index % 2 == 1;
    },
    isWhite(index: number): boolean {
      return index >= 3;
    },
    scrollToSection(section: string) {
      const navbarHeight = parseInt(
        document.body.style.getPropertyValue("--navbar-height")
      );
      if (!section) return;
      const element = document.getElementById(section);
      if (!element) return;

      const y =
        element.getBoundingClientRect().top + window.pageYOffset - navbarHeight;

      window.scrollTo({
        top: y,
        behavior: "smooth"
      });
      this.$route.params.id = "";
    }
  },
  watch: {
    $route() {
      this.scrollToSection(this.$route.params.id as string);
    }
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0 });

    setTimeout(() => {
      this.scrollToSection(this.$route.params.id as string);
    }, 100);
  }
});
