
import { defineComponent } from "vue";
import landingInfo from "@/editable/LandingSection.ts";

interface LandingInfo {
  businessName: string;
  phone: string;
  email: string;
  address: string;
  formQuestion: string;
}

export default defineComponent({
  name: "LandingSection",
  data() {
    return {
      landingInfo: landingInfo as LandingInfo
    };
  },
  computed: {
    phoneLinkFormat(): string {
      return `+1-${this.landingInfo.phone}`;
    },
    phoneDisplayFormat(): string {
      const retval = this.landingInfo.phone.replace("-", ") ");
      return `(${retval}`;
    }
  }
});
