<template>
  <div>
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
    />
    <div class="services-section">
      <div class="inner-width">
        <h1 id="our-services" class="section-title">Our Services</h1>
        <div class="border"></div>
        <div class="services-container">
          <router-link
            class="service-box"
            :to="titleToAddress(service.title)"
            v-for="service of services"
            v-bind:key="service.title"
          >
            <div class="service-icon">
              <i :class="'fas fa-' + service.icon"></i>
            </div>
            <div class="service-title">{{ service.title }}</div>

            <ul class="service-desc">
              <ul v-for="item in service.description" v-bind:key="item">
                {{
                  item
                }}
              </ul>
            </ul>

            <!-- <div v-for="description of descriptions" v-bind:key="description">
              {{ description }}
            </div> -->

            <!-- <div class="service-desc">{{ service.description }}</div> -->
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import services from "@/editable/ServicesSection.ts";

interface Service {
  title: string;
  icon: string;
  // an array of descriptions
  description: string[];
}

export default defineComponent({
  name: "ServicesSection",
  methods: {
    titleToAddress(title: string): string | undefined {
      return "/services/" + title.replaceAll(" ", "-");
    }
  },
  data() {
    return {
      services: services
    };
  }
});
</script>

<style scoped lang="scss">
@import "@/mixins/color-palette";

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

#our-services {
  padding-top: 40px;
  color: color(blue, base);
}
.service-desc {
  color: color(blue, base);
  font-family: PT Serif;
  font-style: normal;
}

.service-title {
  color: color(blue, base);
  font-family: Merriweather;
  font-style: normal;
  font-weight: bold;
}

.inner-width {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  overflow: hidden;
}
.section-title {
  text-align: center;
  color: #ddd;
  font-size: 55px;
}
.border {
  position: 50%;
  width: 30%;
  height: 2px;
  background: color(blue, base);
  margin: 5px auto;
}
.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-box {
  text-decoration: none;
  max-width: 33.33%;
  padding: 10px;
  text-align: center;
  color: #ddd;
  cursor: pointer;
}

.service-icon {
  display: inline-block;
  width: 70px;
  height: 70px;
  border: 3px solid color(blue, base);
  color: color(blue, base);
  transform: rotate(45deg);
  margin-bottom: 30px;
  margin-top: 16px;
  transition: 0.3s linear;
}
.service-icon i {
  line-height: 70px;
  transform: rotate(-45deg);
  font-size: 26px;
}
.service-box:hover .service-icon {
  background: color(blue, base);
  color: #ddd;
}
.service-title {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.service-desc {
  font-size: 14px;
}

@media screen and (max-width: 960px) {
  .service-box {
    max-width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .service-box {
    max-width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .service-box {
    max-width: 100%;
  }
}
</style>
