<template>
  <div id="navbar" v-bind:class="{ clear: isClear, fixed: isFixed }">
    <router-link id="logo" to="/">
      <img src="@/assets/logo.png" alt="logo" />
    </router-link>
    <div id="links">
      <div class="link-container" v-for="link of links" v-bind:key="link.title">
        <router-link
          class="link"
          :to="link.route"
          @mouseover="showDropdown(link.title + '-dropdown')"
          @mouseleave="hideDropdown(link.title + '-dropdown')"
        >
          <p class="link-text">{{ link.title }}</p>

          <div class="dropdown hidden" :id="link.title + '-dropdown'">
            <div
              class="sublink"
              v-for="sublink of link.sublinks"
              v-bind:key="sublink.title"
            >
              <router-link :to="sublink.route">
                {{ sublink.title }}
              </router-link>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <button class="menu-toggle-btn" @click="toggleMenu"></button>
    <div
      class="menu-sheet"
      :class="{ 'is-open': isMenuOpen, 'is-closed': !isMenuOpen }"
    >
      <button class="menu-close-btn" @click="isMenuOpen = false"></button>
      <div class="menu-sheet-content">
        <div v-for="link in links" :key="link.title" class="link">
          <router-link v-if="link.sublinks === undefined" :to="link.route">
            {{ link.title }}
          </router-link>
          <div v-else class="link-dropdown">
            <p
              @click="
                toggleMobileDropdown('mobile-' + link.title + '-dropdown')
              "
            >
              {{ link.title }}
            </p>
            <div
              class="dropdown-container hidden"
              :id="'mobile-' + link.title + '-dropdown'"
            >
              <router-link
                v-for="sublink in link.sublinks"
                :key="sublink.title"
                :to="sublink.route"
                class="sublink"
              >
                {{ sublink.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import links from "@/editable/Navbar.ts";

interface Link {
  title: string;
  route: string;
  sublinks: Link[];
}

export default defineComponent({
  name: "Navbar",
  props: {
    isClear: Boolean,
    isFixed: Boolean
  },
  data() {
    return {
      isMenuOpen: false,
      links: links as Link[]
    };
  },
  methods: {
    showDropdown(id: string) {
      const dropdown = document.getElementById(id);
      dropdown?.classList.remove("hidden");
    },

    hideDropdown(id: string) {
      const dropdown = document.getElementById(id);
      dropdown?.classList.add("hidden");
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    toggleMobileDropdown(id: string) {
      const dropdown = document.getElementById(id);
      dropdown?.classList.toggle("hidden");
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/mixins/color-palette";
@import "@/mixins/media";

$navbarHeight: 56px;

#navbar {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  background-color: rgba(color(blue, darker), 1);
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.41));
  min-height: var(--navbar-height);
  height: var(--navbar-height);
  font-size: 14pt;

  &.fixed {
    position: fixed;
  }

  &.clear {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, min(var(--scroll), 0.41)));

    @include desktop-view() {
      background-color: rgba(color(blue, darker), min(var(--scroll), 1));
      height: calc(80px * (1 - var(--scroll)) + 30px);
    }

    .link {
      position: relative;

      @include desktop-view() {
        font-size: max(14pt, calc(14px * (1 - var(--scroll)) + 10px));
      }

      @include mobile-view() {
        margin-bottom: 16px;
      }
    }
  }

  @include desktop-view() {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include mobile-view() {
    height: var(--navbar-height);
    min-height: var(--navbar-height);

    .link {
      margin-bottom: 16px;
    }
  }
}

#links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: 100%;

  @include mobile-view() {
    display: none;
  }
}

#logo {
  position: absolute;
  left: 50px;
  height: 50%;

  img {
    height: 100%;
  }

  @include mobile-view() {
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
}

.link-container {
  display: flex;
  height: 100%;

  .link {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

a,
.link-dropdown {
  color: whitesmoke;
  text-decoration: none;

  @include desktop-view() {
    font-size: 14pt;
  }

  @include mobile-view() {
    font-size: 14pt;
  }
}

.hidden {
  @include desktop-view() {
    opacity: 0;
    width: 0 !important;
    transition: all 0.4s !important;
  }

  @include mobile-view() {
    display: none;
  }
}

.dropdown {
  transition-duration: 0.2s;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  padding-top: 0;
  width: 370px;
  overflow: hidden;

  .sublink {
    background-color: color(blue, darker);
    border-top: color(blue, base) solid 2px;
    font-size: 1.1rem;

    a {
      display: block;
      padding: 20px;
      width: 100%;
      white-space: nowrap;
      font-size: 1rem;
    }
  }

  .sublink:hover {
    a {
      transition-duration: 0.2s;
      font-size: 1.1rem;
    }
  }
}

.menu-toggle-btn {
  $size: 32px;

  position: absolute;
  right: 16px;
  background-color: transparent;
  background-image: url("../assets/menu-icon.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: $size;
  width: $size;
  height: 100%;
  border: none;

  @include desktop-view() {
    display: none;
  }
}

.menu-sheet {
  background-color: color(blue, darker);
  color: white;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  text-align: center;
  transition: transform 0.5s ease-in-out;

  &.is-open {
    transform: translateY(0%);
  }

  &.is-closed {
    transform: translateY(-100%);
  }

  @include desktop-view() {
    display: none;
  }
}

.menu-sheet-content {
  padding: 32px 16px 16px 16px;
}

.menu-close-btn {
  $size: 32px;

  position: absolute;
  right: 16px;
  top: 16px;
  width: $size;
  height: $size;
  border: none;
  font-size: 32px;
  z-index: 2;
  color: whitesmoke;
  background-color: transparent;

  &::before {
    content: "\00d7";
  }

  @include desktop-view() {
    display: none;
  }
}

.link-dropdown {
  cursor: pointer;
  user-select: none;

  p {
    font-size: 14pt;
    color: whitesmoke;
    background-color: transparent;
    border: none;
    position: relative;
  }

  p::after {
    content: ">";
    width: 10px;
    margin-left: 11px;
    transform: rotate(90deg) scale(0.6, 1.3);
    height: 100%;
    position: absolute;
    color: var(--color-text);
    font-family: serif;
    font-weight: 400;
    text-align: center;
  }

  a {
    display: block;
    margin-bottom: 16px;
  }
}
</style>
