
import { defineComponent } from "vue";
import aboutText from "@/editable/AboutSection.ts";

// Include all images
for (const about of aboutText) {
  about.image = require(`@/assets/${about.image}`);
}

interface About {
  image: string;
  title: string;
  details: string;
}

export default defineComponent({
  name: "AboutSection",
  data() {
    return {
      aboutText: aboutText as About[]
    };
  },
  mounted() {
    window.addEventListener("scroll", () =>
      requestAnimationFrame(() => {
        const containers = document.getElementsByClassName(
          "container-about"
        ) as HTMLCollectionOf<HTMLElement>;

        for (const container of containers) {
          const scrollProgress =
            1 - container.getBoundingClientRect().top / window.outerHeight;
          let slideProgress = 400 - container.getBoundingClientRect().top;

          if (slideProgress > 0) slideProgress = 0;
          if (container.classList.contains("reverse")) slideProgress *= -1;

          container.style.opacity = `min(${scrollProgress} + 0.3, 1)`;
          container.style.transform = `translateX(${slideProgress}px)`;
        }
      })
    );
  }
});
