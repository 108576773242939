import YAML from "yaml";

// If you are editing this on GitHub, you might want to enable wrapping.
// There should be a button that says nowrap which can be changed to soft wrap.
// All You need to do is edit the text and press commit changes.
// This will cause your changes to take place on your website automatically.
export default YAML.parse(`
  - title: Estate Planning for Healthcare
    icon: stethoscope
    description:
    - Healthcare Surrogates
    - Living Wills
    - Declaration of Pre-need Guardian for Children and Adults
  - title: Estate Planning for Families
    icon: users
    description:
    - Durable Power of Attorney
    - Last Will and Testament
    - Trusts
    - Trust Management
    - Advance Healthcare Directives
    - Disability Planning
  - title: Estate Planning for Seniors and Elderly Adults
    icon: hands
    description:
    - Heritage Planning
    - Last Will and Testament
    - Trusts
    - Advance Healthcare Directives
    - Declaration of Pre-need Guardian
    - Consultations on Guardianships
    - Disability Planning
  - title: Business and Succession Planning
    icon: handshake
    description:
    - Establish Family Limited Partnerships
    - Establish Limited Liability Campanies (LLC)
    - Creation of Family Foundations
    - Trusts
    - Family Meetings
    - Contracts
  - title: Trusts and Real Estate
    icon: home
    description:
    - Revocable Living Trusts
    - Charitable Trusts
    - Credit Shelter Trusts
    - Irrevocable Trusts
    - Special Needs Trusts
    - Deeds
    - Pet Trusts
    - Purchases and Sales of Real Property
    - Rezoning of Real Property
  - title: Trust Administration and Probate
    icon: gavel
    description:
    - Probate Avoidance Planning
    - Formal Administration of Estates
    - Summary Administration of Estates
    - Personal Representatative Services
    - Trust Adminisration
    - Trustee Services
`);
