<template>
  <meta
    name="viewport"
    content="user-scalable=no, width=device-width, initial-scale=1.0"
  />
  <meta name="apple-mobile-web-app-capable" content="yes" />
  <section id="landing">
    <header>
      <h1 v-html="landingInfo.businessName"></h1>
      <h2>{{ landingInfo.slogan }}</h2>
      <router-link to="/home/contact" class="contact-btn">
        Contact Us
      </router-link>
      <a :href="`tel:${phoneLinkFormat}`" class="link">
        {{ phoneDisplayFormat }}
      </a>
    </header>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import landingInfo from "@/editable/LandingSection.ts";

interface LandingInfo {
  businessName: string;
  phone: string;
  email: string;
  address: string;
  formQuestion: string;
}

export default defineComponent({
  name: "LandingSection",
  data() {
    return {
      landingInfo: landingInfo as LandingInfo
    };
  },
  computed: {
    phoneLinkFormat(): string {
      return `+1-${this.landingInfo.phone}`;
    },
    phoneDisplayFormat(): string {
      const retval = this.landingInfo.phone.replace("-", ") ");
      return `(${retval}`;
    }
  }
});
</script>

<style scoped lang="scss">
$color-btn: #348f00;

#landing {
  background-image: url("../assets/ocean.jpg");
  height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  font-family: Merriweather;
  color: white;
  text-align: center;

  h1,
  h2 {
    font-weight: normal;
    margin: 0;
  }

  h1 {
    font-size: max(1.5em, 2.8vw);
    margin-bottom: 0.5em;
    line-height: 1.4em;
  }

  h2 {
    font-size: max(1em, 1.9vw);
    margin-bottom: 1.5em;
    letter-spacing: 1px;
  }
}

.contact-btn {
  display: inline-block;
  color: white;
  background-color: $color-btn;
  border-radius: 10px;
  border: none;
  padding: 0.7em 1.5em;
  font-size: max(1em, 1.2vw);
  transition: background-color 0.3s ease-in-out;
  font-family: Merriweather;
  letter-spacing: 1px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: darken($color-btn, 7%);
  }
}

.link {
  display: block;
  color: white;
  margin-top: 1em;
  font-size: max(1em, 1.6vw);
  text-decoration: none;
  font-family: Merriweather;
  letter-spacing: 1px;
}
</style>
