<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "app",
  mounted() {
    const root = this.myprop || "/home";
    this.$router.push({ path: root });
  }
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

li {
  margin: 10px 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: filter 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  filter: blur(2px);
}
</style>
