
import { defineComponent } from "vue";
import Modal from "@/components/Modal.vue";
import contactInfo from "@/editable/ContactSection.ts";

interface ContactInfo {
  businessName: string;
  phone: string;
  email: string;
  address: string;
}

const defaultFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  feedback: ""
};

export default defineComponent({
  name: "ContactSection",
  components: {
    Modal
  },
  data() {
    return {
      contactInfo: contactInfo as ContactInfo,
      googleDocsUrl:
        "https://docs.google.com/forms/u/0/d/e/1FAIpQLSc2KifG0h0tiGDLJgdFjqbrNm55pAXSSkbColD0nTjjcQMcRw/formResponse",
      isSubmitDisabled: false,
      formState: {
        ...defaultFormState
      },
      modalProps: {
        isOpen: false,
        title: "",
        body: ""
      }
    };
  },
  computed: {
    phoneLinkFormat(): string {
      return `+1-${this.contactInfo.phone}`;
    },
    phoneDisplayFormat(): string {
      const retval = this.contactInfo.phone.replace("-", ") ");
      return `(${retval}`;
    }
  },
  methods: {
    showModal(title: string, body: string) {
      this.modalProps = {
        title,
        body,
        isOpen: true
      };
    },
    onModalClose() {
      this.modalProps.isOpen = false;
    },
    clearForm() {
      (this.$refs.contactForm as HTMLFormElement).reset();
      this.formState = { ...defaultFormState };
    },
    isInputValid() {
      // Matches:
      //  (123) 456-7890
      //  (123)456-7890
      //  123-456-7890
      //  123.456.7890
      //  1234567890
      //  +31636363634
      //  075-63546725
      const phoneNumberRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
      // Matches: text@text.text
      const emailRegex = /\S+@\S+\.\S+/;
      const {
        firstName,
        lastName,
        email,
        feedback,
        phoneNumber
      } = this.formState;

      if (
        !firstName.trim() ||
        !lastName.trim() ||
        !email.trim() ||
        !feedback.trim()
      ) {
        return false;
      }

      if (!emailRegex.test(email)) {
        return false;
      }

      if (phoneNumber.trim() && !phoneNumberRegex.test(phoneNumber)) {
        return false;
      }

      return true;
    },
    submitForm(event: Event) {
      event.preventDefault();

      if (!this.isInputValid()) {
        this.showModal(
          "Check your responses",
          `Please make sure you've filled out the required fields and
          all of your responses are valid, then try again.`
        );
        return;
      }

      this.isSubmitDisabled = true;

      const contactForm = this.$refs.contactForm as HTMLFormElement;

      fetch(this.googleDocsUrl, {
        method: "POST",
        body: new FormData(contactForm),
        mode: "no-cors"
      })
        .then(() => {
          this.clearForm();
          this.showModal(
            "Thanks!",
            `Your response has been recorded. We'll be
            sure to get back to you shortly.`
          );
        })
        .catch(() => {
          this.showModal(
            "Uh-Oh!",
            `Something went wrong while processing
            your request. Please try again later.`
          );
        })
        .finally(() => (this.isSubmitDisabled = false));
    }
  }
});
