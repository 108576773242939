
import { defineComponent } from "vue";
import staff from "@/editable/StaffSection.ts";

interface Staff {
  name: string;
  paragraphs: string[];
}

export default defineComponent({
  name: "StaffSection",
  data() {
    return {
      staff: staff
    };
  }
});
