<template>
  <div>
    <h1 class="title">Our Legal Staff</h1>
    <div class="border"></div>

    <div class="flex-container">
      <div class="staff">
        <img class="image" src="@/assets/joy.jpg" alt="" />
        <div class="box">
          <div class="name-text">
            <h1>{{ staff.name }}</h1>
          </div>
        </div>
      </div>
      <div class="text">
        <h1 id="name-text">{{ staff.name }}</h1>
        <p v-for="paragraph of staff.paragraphs" :key="paragraph">
          {{ paragraph }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import staff from "@/editable/StaffSection.ts";

interface Staff {
  name: string;
  paragraphs: string[];
}

export default defineComponent({
  name: "StaffSection",
  data() {
    return {
      staff: staff
    };
  }
});
</script>

<style scoped lang="scss">
@import "@/mixins/color-palette";
.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.name-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  margin: 0;
  padding: 0;
  font-family: PT Serif;
}
.box {
  background-color: color(blue, base);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
}
.staff .title {
  //position: absolute;
  z-index: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 85%; /* Adjust this value to move the positioned div up and down */
  background: color(blue, base);
  color: #fff;
  width: 60%; /* Set the width of the positioned div */
}
.staff {
  min-width: 300px;
  width: 35%;
  max-width: 500px;
  position: relative;
  text-align: center;
}
.title {
  text-align: center;
  vertical-align: middle;
  line-height: 90px;
}
.text {
  flex: 1;
  padding: 0 50px;
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 44px;
  color: color(blue, base);
}
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px;
  box-sizing: border-box;
}
.title {
  font-family: Merriweather;
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 69px;
  margin: 0;
  text-align: center;
  padding-top: 70px;
  color: color(blue, base);
}
.border {
  position: 50%;
  width: 20%;
  height: 2px;
  background: color(blue, base);
  margin: 5px auto;
}
// mobile ============================
@media (max-width: 1024px) {
  //623
  .text {
    font-size: 18px;
    //line-height: 25px;
  }
  // take away the name banter
  .flex-container {
    display: inline-block;
    padding: 0px;
  }
  // take away the name banter
  .name-text {
    font-size: 0px;
  }
  .box {
    display: nonere;
  }
  .name {
    text-align: center;
  }

  .title {
    font-size: 35px;
  }

  .image-div {
    width: 100%;
    //display: block;
  }
  .image {
    //height: auto;
    width: 100%;
    height: 30vh;
    margin: 0 !important;
  }
  .border,
  .box {
    display: none;
  }
  #name-text {
    text-align: center;
  }
  .flex-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
</style>
