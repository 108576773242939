import YAML from "yaml";

// If you are editing this on GitHub, you might want to enable wrapping.
// There should be a button that says nowrap which can be changed to soft wrap.
// All You need to do is edit the text and press commit changes.
// This will cause your changes to take place on your website automatically.
export default YAML.parse(`
  businessName: 'Crown Heritage Estate Planning Law Firm, P.L.L.C.'
  phone: '407-863-2712'
  email: 'joycarmichael@outlook.com'
  address: '212 W. Bay Avenue, Suite #104, Longwood, Florida 32750'
  formQuestion: 'Have any questions, comments, or concerns?'
`);
