import YAML from "yaml";

// The <br/> represents where the text goes to a new line
// If you are editing this on GitHub, you might want to enable wrapping.
// There should be a button that says nowrap which can be changed to soft wrap.
// All You need to do is edit the text and press commit changes.
// This will cause your changes to take place on your website automatically.
export default YAML.parse(`
  businessName: 'Crown Heritage <br /> Estate Planning Law Firm, P.L.L.C.'
  slogan: 'Planning for Peace of Mind'
  phone: '407-863-2712'
`);
